import { doc, getDoc } from "firebase/firestore";
import Web3 from "web3";
import { BaseInfo, getUserBalances, tokeABI } from "./const";
import { auth, db } from "../firebase";
import { toast } from "react-toastify";
import { addDays } from "date-fns";
import { useGameType } from "../theme";

export function copyString(str, msg) {
  navigator.clipboard.writeText(str);
  toast.success(msg, {
    position: "top-left",
    autoClose: 1500,
    hideProgressBar: false,
  });
}
export function isFromMiniApp() {
  const queryParams = new URLSearchParams(window.location.search);
  const fromParam = queryParams.get("from");

  return fromParam === "miniapp";
}
export function playerPlaceholderImg(uid) {
  return `https://api.dicebear.com/9.x/pixel-art/png?scale=80&backgroundColor=d1d4f9,c0aede,b6e3f4,ffd5dc,ffdfbf&seed=${uid}`;
}
export function getLevelInd(userBalances) {
  const ghub = userBalances?.balances?.ghub?.amount ?? 0;

  const curLev = getLevel(ghub);
  return BaseInfo.levels2.indexOf(curLev);
}
export function getLevel(ghubBal) {
  const curLev = [...BaseInfo.levels2]
    .reverse()
    .find((level) => ghubBal >= level.GHUB_required);
  return curLev;
}
/* 
export function getTimeTilFryday(){
  const now = new Date();
  const currentDayOfWeek = now.getUTCDay();
  const currentHour = now.getUTCHours();
  const currentMinutes = now.getUTCMinutes();

  let diffDays = 0;
  let diffHours = 0;
  let diffMinutes = 0;

  if (currentDayOfWeek === 5 && currentHour >= 0 && currentHour < 23) {
    diffHours = 23 - currentHour;
    diffMinutes = 59 - currentMinutes;
  } else {
    diffDays = 5 - currentDayOfWeek;
    if (currentDayOfWeek > 5 || (currentDayOfWeek === 5 && currentHour >= 23)) {
      diffDays += 7; 
    }
    diffHours = Math.floor(((24 - currentHour) + (diffDays * 24) - 1) / 60);
    diffMinutes = 59 - currentMinutes; 
  }

  return `${diffDays} days ${diffHours} hours ${diffMinutes} minutes`;
}
export function getTimeTilSaturday(){
  var now = new Date();
    var hours = now.getUTCHours();
    var minutes = now.getUTCMinutes();

    var hoursLeft = 17 - hours;
    var minutesLeft = 60 - minutes;

    return hoursLeft + " hours " + minutesLeft + " minutes";
}
export function isBetweenFridayAndSaturdaySixPMUTC() {
  const date = new Date();
  var today = new Date();

    var currentDay = today.getUTCDay();
    if (currentDay === 5 && today.getUTCHours() >= 23 && today.getUTCMinutes() >= 59) {
        return true;
    }
    if (currentDay === 6 && today.getUTCHours() < 18) {
        return true;
    }
    return false;
} */
export function formatTimeAgoMinutes(milliseconds) {
  const seconds = Math.floor((Date.now() - milliseconds) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (minutes < 1) {
    return "Just now";
  } else if (minutes === 1) {
    return "1 minute ago";
  } else if (minutes < 60) {
    return `${minutes} minutes ago`;
  } else if (hours === 1) {
    return "1 hour ago";
  } else if (hours < 24) {
    return `${hours} hours ago`;
  } else if (days === 1) {
    return "1 day ago";
  } else {
    return `${days} days ago`;
  }
}

export function formatNumber(num, maxDecimal = 5) {
  let formatted = (num ?? 0).toFixed(maxDecimal);

  // Rimuove i zeri finali se ce ne sono
  formatted = parseFloat(formatted).toString();

  return formatted;
}
function getDaysPassedFromStartTimeComp() {
  const start_date = BaseInfo.p2eGames.start_comp;
  const currentDate = new Date();
  currentDate.setUTCHours(0, 0, 0, 0);
  const pastDate = new Date(start_date);
  pastDate.setUTCHours(0, 0, 0, 0);

  const diffInMs = currentDate - pastDate;

  const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
  const daysPassed = Math.floor(diffInDays);

  return daysPassed;
}
export function isP2eCompActive() {
  const start = new Date(BaseInfo.p2eGames.start);
  const end = new Date(BaseInfo.p2eGames.end);

  const now = new Date(Date.now());

  return now >= start && now <= end;

  const daysPassed = getDaysPassedFromStartTimeComp();
  //se diff pari allora è il giorno che comicia, alle 16
  //se diff è dispari allora è il secondo giorno e dura tutto il giorno, finisce alle 23.59
  const currentDate = new Date();

  if (daysPassed % 2 === 0) {
    return currentDate.getUTCHours() >= 16;
  }
  return true;
}

export function isP2EStarting() {
  return BaseInfo.p2eGames.show && !isP2eCompActive();

  const daysPassed = getDaysPassedFromStartTimeComp();

  const now = new Date();

  if (daysPassed % 2 === 0) {
    const startP2eDate = getDateStartP2eComp(); // Ottieni la data di inizio del P2E
    const showBeforeMinutes = BaseInfo.p2eGames.show_before_min;

    // Calcola la data target sottraendo i minuti dalla data di inizio
    const targetDate = new Date(
      startP2eDate.getTime() - showBeforeMinutes * 60000
    );

    // Confronta l'ora corrente UTC con l'ora target
    return now >= targetDate && now < startP2eDate;
  }
  return false;
}

export function getDateFinishP2eComp() {
  return new Date(BaseInfo.p2eGames.end);

  const daysPassed = getDaysPassedFromStartTimeComp();
  const now = new Date();

  if (daysPassed % 2 === 0) {
    const tomorrow = addDays(now, 1);

    const tomorrowUTC = new Date(
      Date.UTC(
        tomorrow.getUTCFullYear(),
        tomorrow.getUTCMonth(),
        tomorrow.getUTCDate(),
        23,
        59,
        59
      )
    );

    return tomorrowUTC;
  }
  const today = new Date(
    Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      23,
      59,
      59
    )
  );
  return today;
}
export function getDateStartP2eComp() {
  return new Date(BaseInfo.p2eGames.start);

  const daysPassed = getDaysPassedFromStartTimeComp();
  const now = new Date();

  if (daysPassed % 2 !== 0) {
    return false;
  }

  const today = new Date(
    Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      16,
      0,
      0
    )
  );
  return today;
}

export function formatNumberInStr(num, large = false) {
  if (!num) return "0";

  if (num >= 1e9) {
    // Billion
    return (
      (num / 1e9).toLocaleString("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 1,
      }) + "B"
    );
  } else if (num >= 1e6) {
    // Million
    return (
      (num / 1e6).toLocaleString("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 1,
      }) + "M"
    );
  } else if (num >= 1e3) {
    // Thousand
    if (!large)
      return (
        (num / 1e3).toLocaleString("en-US", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 1,
        }) + "K"
      );
    return num.toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  } else {
    // Less than thousand
    return num.toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 1,
    });
  }
}
