import { useEffect, useState } from "react";
import { auth, functions } from "../firebase";
import { LoadingScreen } from "../compnents/otherCOmps";
import { httpsCallable } from "firebase/functions";
import { showErrorNotification } from "../compnents/errorMessagesComp";
import { CasinoApi } from "./api";
import { useGameType } from "../theme";

export default function CasinoApp() {
  const [link, setLink] = useState(null);
  const { setGameType } = useGameType();
  const [iframeHeight, setIframeHeight] = useState(window.innerHeight - 5);

  useEffect(() => {
    getLink();
  }, []);

  useEffect(() => {
    function handleMessage(event) {
      if (event.data === "showBackButton") {
        window.Telegram.WebApp.BackButton.show();
      } else if (event.data === "hideBackButton") {
        window.Telegram.WebApp.BackButton.hide();
      } else if (event.data === "backHome") {
        setGameType("init");
      }
    }

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const updateHeight = () => {
    setIframeHeight(window.innerHeight - 3);
  };

  useEffect(() => {
    window.addEventListener("resize", updateHeight);

    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  useEffect(() => {
    window.Telegram.WebApp.BackButton.onClick(() => {
      const iframe = document.getElementById("casino-iframe");
      if (iframe) {
        iframe.contentWindow.postMessage("onClickBack", "*");
      }
    });
  }, []);

  async function getLink() {
    try {
      const url = await CasinoApi.getCasinoLink();
      setLink(url);
    } catch (error) {
      showErrorNotification("Error");
    }
  }
  if (!link) {
    return <LoadingScreen />;
  }
  return (
    <iframe
      title="casino"
      id="casino-iframe"
      width={"100%"}
      height={iframeHeight}
      style={{ border: "none" }}
      src={link}
    />
  );
}
