import { httpsCallable } from "firebase/functions";
import { useEffect, useState } from "react";
import { auth, functions } from "../firebase";
import {
  RColumn,
  RRow,
  RText,
  RTitile,
  SecondaryBtn,
  Space,
  SpaceHorizontal,
} from "../compnents/otherCOmps";
import SpinningCircles from "react-loading-icons/dist/esm/components/spinning-circles";
import { useNavigate } from "react-router-dom";
import { telegramID, tgUsernameGlob } from "../aStartingPage";
import { ColorPalette } from "../global/const";
import { useGameType } from "../theme";

export default function QTCasinoPage() {
  const [isLoading, setIsLoading] = useState(true);

  const [h, setH] = useState(window.innerHeight);

  const [evenBetUrl, setEvenBetUrl] = useState(null);
  const { gameType, setGameType } = useGameType();
  const nav = useNavigate();

  useEffect(() => {
    getAccordionDetailsUtilityClass();
  }, []);

  async function getAccordionDetailsUtilityClass() {
    console.log("start");
    const funct = httpsCallable(functions, "requestQTLobby");

    try {
      const res = await funct({});
      console.log(res.data);
      setEvenBetUrl(res.data["url"]);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleResize = () => {
    setH(window.innerHeight);
  };
  useEffect(() => {
    const checkIframeUrl = () => {
      const iframe = document.getElementById("frame");
      if (iframe && iframe.contentWindow) {
        try {
          const currentUrl = iframe.contentWindow.location.href;

          console.log(currentUrl);
          const myUrl = window.location.href;
          console.log(myUrl);

          if (myUrl === currentUrl) {
            console.log("exited");
            clearInterval(intervalId);
            nav("/?username=" + tgUsernameGlob + "&id=" + telegramID);
          }
        } catch (error) {}
      }
    };

    const intervalId = setInterval(checkIframeUrl, 1000); // Controlla l'URL dell'iframe ogni secondo

    return () => clearInterval(intervalId);
  }, []);

  if (isLoading) {
    return (
      <RColumn
        verticalAlignment="center"
        horizontalAlign="center"
        height={"99vh"}
      >
        <img src="gameIcons/casino.png" width={"180px"} alt="" />
        <RTitile>Loading Casino lobby</RTitile>
        <Space />
        <SpinningCircles />
        <Space height="80px" />
      </RColumn>
    );
  }
  return (
    <>
      <div
        style={{
          background: ColorPalette.bgGradient,
          height: "80px",
          width: "98%",
        }}
      >
        <RRow
          verticalAlignment="center"
          height={"100%"}
          horizontalAlign="space-between"
        >
          <img
            alt=""
            src={auth.currentUser.photoURL}
            width={"50px"}
            style={{ borderRadius: "100%" }}
          />
          <RRow>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setGameType("init")}
            >
              <RText>Home</RText>
            </div>
            <SpaceHorizontal />
            <SecondaryBtn text={"Wallet"} />
          </RRow>
        </RRow>
      </div>
      <iframe
        id="frame"
        src={evenBetUrl}
        style={{ border: "none" }}
        width={"100%"}
        height={h}
      />
    </>
  );
}
