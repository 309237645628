import { useEffect, useState } from "react";
import {
  InputField,
  LoadingScreen,
  RColumn,
  RGrid,
  RRow,
  RText,
  RTitile,
  SecondaryBtn,
  Space,
  SpaceHorizontal,
} from "../compnents/otherCOmps";
import { supabase } from "../supabase";
import axios from "axios";
import { auth } from "../firebase";
import casinoBannImg from "../assets/Banner Casino.jpg";
import { useGameType } from "../theme";
import { BaseInfo, ColorPalette, isTest, UserInfo } from "../global/const";
import WalletPage from "../wallet/walletPgae";
import {
  RiArrowGoBackLine,
  RiArrowLeftFill,
  RiCashFill,
  RiCloseLine,
  RiDice1Line,
  RiDice2Line,
  RiFileCopyLine,
  RiGamepadLine,
  RiGroupLine,
  RiMoneyDollarCircleLine,
  RiProfileFill,
  RiTelegramLine,
  RiUser2Line,
  RiUserLine,
  RiWalletLine,
} from "@remixicon/react";
import {
  ContainerBorder,
  DropdownMenu,
  DropdownMenuItem,
  HorizontalScrollAnimated,
  HorizontalScrollContainer,
  HorizontalScrollElement,
  MaxWidthContainer,
} from "../compnents/compes";
import { debounce } from "lodash";
import { CasinoApi } from "./api";
import { isDesktop } from "react-device-detect";
import { isFromMiniApp } from "../global/util";
import LoadingIcons from "react-loading-icons";
import ReferralPage from "../referral/referralPages";
import { greenColor } from "../profile/profilePage";
import { toast } from "react-toastify";
import gambleAwaereImg from "../assets/gambleAware.png";
import chipIcon from "../assets/casino-chip.png";
import diceIcon from "../assets/dice.png";
import cardsIcon from "../assets/card-game.png";
import historyIcon from "../assets/history.png";
import cherryIcon from "../assets/cherry.png";
import slotIcon from "../assets/jackpot.png";

export default function QTLobbyPage({ refCode }) {
  const linkRef =
    (isTest
      ? "https://t.me/test_hub_games_bot/games?startapp=ref="
      : "https://t.me/TheGameHubBot/play?startapp=ref=") + refCode;

  function inviteFriends() {
    const link = `https://t.me/share/url?url=${linkRef}&text=🕹️Join%20The%20Game%20Hub%20using%20my%20referral%20link`;
    if (isFromMiniApp) {
      window.Telegram.WebApp.openTelegramLink(link);
    } else {
      window.open(link, "_blank");
    }
  }
  function shareToStory() {
    const description =
      "Join TheGameHub with my ref code to earn points!\n" + linkRef;
    if (isFromMiniApp) {
      window.Telegram.WebApp.shareToStory(
        "https://testgamehub.crabsgames.com/botImg/storyvideo-2.mp4",
        {
          text: description,
        }
      );
    } else {
      window.alert("Not for desktop");
    }
  }
  function copyCode() {
    navigator.clipboard.writeText(linkRef);
    toast.success("Referral code copied", {
      position: "top-left",
      autoClose: 2000,
      hideProgressBar: false,
    });
  }
  const [iframeHeight, setIframeHeight] = useState(window.innerHeight - 5);

  const [games, setGames] = useState([]);
  const [filteredGames, setFilteredGames] = useState([]);
  const [lastPlayed, setLastPlayed] = useState([]);
  const [lastBets, setLastBets] = useState([]);
  const [providers, setProviders] = useState([{ name: "", id: "", img: "" }]);
  const [filter, setFilter] = useState({
    type: "",
    active: false,
    value: "",
    label: "",
  });

  const {
    setGameType,
    userBalances,
    updateUserBalances,
    qtSelectedCurrency,
    setQtSelectedCurrency,
  } = useGameType();

  const pages = [
    { icon: RiUserLine, label: "Profile", index: 1 },
    { icon: RiDice2Line, label: "Games", index: 0 },
    { icon: RiWalletLine, label: "Wallet", index: 2 },
    { icon: RiGroupLine, label: "Referral", index: 3 },
  ];

  const [pageSelected, setPageSelected] = useState(0);

  // const [state, setState] = useState("home");

  const filterGame = [
    { name: "Slot", query: "SLOT", icon: slotIcon },
    { name: "Bonus buy", query: "BONUS_BUY" },
    { name: "Live casino", query: "LIVECASINO" },
    { name: "Blackjack", query: "BLACKJACK" },
    { name: "Instant win", query: "INSTANTWIN" },
    ...[
      { name: "Roulette", query: "ROULETTE" },
      { name: "Table game", query: "TABLEGAME" },
      { name: "Lottery", query: "LOTTERY" },
      { name: "Scratch card", query: "SCRATCH_CARD" },
      { name: "Poker", query: "POKER" },
      { name: "Crash", query: "CRASH" },
      { name: "Coin flip", query: "COIN_FLIPPING" },
      { name: "4REEL", query: "4REEL" },
      { name: "Higher lower", query: "HIGHER_LOWER" },
      { name: "Mines", query: "MINES" },
    ].sort(() => Math.random() - 0.5),
  ];

  const [currentGame, setCurrentGame] = useState({
    url: "",
    active: false,
    loading: false,
  });

  const [searchTerm, setSearchTerm] = useState("");

  const [topGames, setTopGames] = useState([]);

  const debouncedGetGames = debounce(getGamesSearch, 300); // 300 ms di ritardo

  const balancesAmount = BaseInfo.baseInfoDoc.coinSupported
    .filter((x) => x.symbol.toLowerCase() !== "ghub")
    .map((v) => ({
      ...v,
      tkn_amount: userBalances?.balances?.[v.symbol.toLowerCase()]?.amount ?? 0,
      usd_amount:
        userBalances?.balances?.[v.symbol.toLowerCase()]?.usd_amount ?? 0,
    }));
  balancesAmount.sort((a, b) => {
    if (b.usd_amount !== a.usd_amount) {
      return b.usd_amount - a.usd_amount;
    }
    return b.tkn_amount - a.tkn_amount;
  });

  const updateHeight = () => {
    setIframeHeight(window.innerHeight - 2);
  };

  useEffect(() => {
    window.addEventListener("resize", updateHeight);

    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  useState(() => {
    CasinoApi.getPopularGames().then((g) => setTopGames(g));
    CasinoApi.getLastPlayed().then((g) => setLastPlayed(g));
    CasinoApi.getProviders().then((p) => setProviders(p));

    getGamesCats();
  }, []);
  useEffect(() => {
    debouncedGetGames();
    return () => {
      debouncedGetGames.cancel();
    };
  }, [searchTerm]);

  useEffect(() => {
    updateUserBalances();
    if (pageSelected === 0) {
      CasinoApi.getLastBets().then((l) => setLastBets(l));
    }
  }, [pageSelected, currentGame]);

  const [ngr, setNgr] = useState();
  useEffect(() => {
    if (pageSelected === 1) {
      CasinoApi.getNgr().then((n) => setNgr(n));
    }
  }, [pageSelected]);

  useEffect(() => {
    if (filter.active) getGamesFilter(filter);
  }, [filter]);

  async function getGamesCats() {
    const proms = filterGame.map(async (cat) => {
      if (cat.query === "BONUS_BUY") {
        const { data, error } = await supabase.rpc(
          "get_qt_games_with_bonus_buy"
        );

        if (error) {
          console.error(
            `Errore nella query per la categoria ${cat.query}:`,
            error
          );
          return null; // Puoi decidere come gestire l'errore
        }
        return {
          cat,
          games: data,
        };
      }
      const { data, error } = await supabase
        .from("qt_games")
        .select("id, name, images")
        .ilike("category", `%${cat.query}%`)
        .limit(20);

      if (error) {
        console.error(
          `Errore nella query per la categoria ${cat.query}:`,
          error
        );
        return null; // Puoi decidere come gestire l'errore
      }

      return {
        cat,
        games: data,
      };
    });
    const results = await Promise.all(proms);

    // Filtra eventuali risultati nulli (in caso di errori)
    const gamesCats = results.filter((res) => res !== null);
    setGames(gamesCats);
  }
  async function getGamesFilter(filter) {
    setFilteredGames([]);
    if (filter.value === "BONUS_BUY") {
      console.log("BB");
      const { data } = await supabase.rpc("get_qt_games_with_bonus_buy");
      setFilteredGames(data);
      return;
    }
    const { data } = await supabase
      .from("qt_games")
      .select("id, name, images")
      .ilike(filter.type, `%${filter.value}%`)
      .limit(100);
    setFilteredGames(data);
  }
  async function getGamesSearch() {
    let query = supabase.from("qt_games").select("id, name, images").limit(50);

    if (searchTerm) {
      query = query.ilike("name", `%${searchTerm}%`);
    }
    const { data, error } = await query;

    if (error) {
      console.log("Error fetching games:", error);
    } else {
      setFilteredGames(data);
    }
  }

  function handleSearchChange(e) {
    setSearchTerm(e.target.value);
  }

  async function getGameUrl(id) {
    window.Telegram.WebApp.BackButton.show();
    window.Telegram.WebApp.BackButton.onClick(() => {
      setCurrentGame({ active: false, url: "" });
      window.Telegram.WebApp.BackButton.hide();
    });

    const token = await auth.currentUser.getIdToken();
    setCurrentGame({ url: "", active: false, loading: true });

    try {
      const res = await axios.post(
        "https://uno.thegamehub.gg:3009/getgameurl",
        {
          gameID: id,
          userToken: token,
          uid: auth.currentUser.uid,
          fromTg: true,
        },
        {
          headers: { sign: "7qf1oyipq5m7od27n75kli2y8c7iek3x" },
        }
      );
      setCurrentGame({
        url: res.data.url + "&hideSplash=true",
        active: true,
        loading: false,
      });
    } catch (e) {
      console.log(e);
    }
  }

  function SingleGame({ game, width = "100px" }) {
    const [isLoading, setIsLoading] = useState(true);

    return (
      <div
        style={{
          position: "relative",
          width: width,
          borderRadius: "12px",
          minHeight: "100px",
          maxWidth: "130px",
          cursor: "pointer",
          overflow: "hidden",
        }}
        onClick={() => getGameUrl(game.id)}
      >
        {isLoading && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100px",
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "14px",
              zIndex: 1,
              textAlign: "center",
            }}
          >
            {game.name}
          </div>
        )}
        <img
          alt={game.name}
          src={game.images?.[2]?.url ?? game.images[0].url}
          width="100%"
          style={{
            borderRadius: "12px",
            objectFit: "cover",
            position: "relative",
            zIndex: 2,
          }}
          onLoad={() => setIsLoading(false)}
        />
      </div>
    );
  }
  function SingleProvider({ game: provider, width = "100px" }) {
    const [isLoading, setIsLoading] = useState(true);

    return (
      <div
        style={{
          position: "relative",
          width: width,
          borderRadius: "12px",
          minHeight: "100px",
          maxWidth: "130px",
          cursor: "pointer",
          overflow: "hidden",
        }}
        onClick={() =>
          setFilter({
            active: true,
            type: "provider_id",
            value: provider.id,
            label: provider.name,
          })
        }
      >
        {isLoading && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100px",
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "14px",
              zIndex: 1,
              textAlign: "center",
            }}
          >
            {provider.name}
          </div>
        )}
        <img
          alt={provider.name}
          src={provider.img}
          width="100%"
          style={{
            borderRadius: "12px",
            objectFit: "cover",
            position: "relative",
            zIndex: 2,
          }}
          onLoad={() => setIsLoading(false)}
        />
      </div>
    );
  }
  if (currentGame?.active) {
    return (
      <>
        {!isFromMiniApp() && (
          <div
            style={{
              cursor: "pointer",
              top: "6px",
              backgroundColor: "white",
              position: "fixed",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              width: "26px",
              height: "26px",
              zIndex: 3,
              borderRadius: "100%",
              left: "47%",
            }}
            onClick={() => setCurrentGame({ active: false, url: "" })}
          >
            <RiCloseLine color="black" size={22} />
          </div>
        )}
        <iframe
          title="game"
          src={currentGame.url}
          width={"100%"}
          height={iframeHeight}
          style={{ border: "none" }}
        />
      </>
    );
  }
  function SectionTitile({ children, icon = cardsIcon }) {
    return (
      <RRow height={"44px"} verticalAlignment="center">
        <img src={icon} height={"20px"} alt="" />
        <SpaceHorizontal width="6px" />
        <RTitile font="Righteous" size="20px">
          {children}
        </RTitile>
      </RRow>
    );
  }

  async function onSelectCurr(currency) {
    try {
      const usTkn = await auth.currentUser.getIdToken();
      await axios.post(
        "https://uno.thegamehub.gg:3009/setSelectedCurrency",
        { currency: currency },
        {
          headers: {
            sign: "7qf1oyipq5m7od27n75kli2y8c7iek3x",
            "user-token": usTkn,
          },
        }
      );
      setQtSelectedCurrency(currency);
    } catch (error) {
      console.log(error);
    }
  }

  function SingleTabBtn({ page }) {
    return (
      <>
        <div
          style={{ cursor: "pointer", width: "100%" }}
          onClick={() => setPageSelected(page.index)}
        >
          <RColumn>
            <page.icon
              color={pageSelected === page.index ? "#E3A75A" : "white"}
              size={24}
            />
            <Space height="6px" />
            <RText
              font="Righteous"
              size="14px"
              weight="bold"
              color={pageSelected === page.index ? "#E3A75A" : "white"}
            >
              {page.label}
            </RText>
          </RColumn>
        </div>
      </>
    );
  }

  return (
    <div
      style={{
        backgroundColor: "#161A2E",
        minHeight: "100vh",
        fontFamily: "Righteous",
      }}
    >
      <Space height="4px" />
      <MaxWidthContainer padding="8px">
        <div
          style={{
            position: "sticky",
            top: 0,
            backgroundColor: "#161A2E",
            zIndex: 10,
            padding: "12px 0px",
            width: "100%",
          }}
        >
          <RRow horizontalAlign="space-between">
            <img
              alt=""
              style={{ cursor: "pointer", marginLeft: "8px" }}
              src="logo.png"
              height={"58px"}
              onClick={() => setGameType("init")}
            />

            <RRow>
              <DropdownMenu
                img={
                  <img
                    src={
                      BaseInfo.baseInfoDoc.coinSupported.find(
                        (c) =>
                          c.symbol.toLowerCase() ===
                          qtSelectedCurrency.toLowerCase()
                      ).img
                    }
                    width={"18px"}
                    alt=""
                  />
                }
                buttonLabel={
                  "$" +
                  balancesAmount
                    .find(
                      (c) =>
                        c.symbol.toLowerCase() ===
                        qtSelectedCurrency.toLowerCase()
                    )
                    .usd_amount.toFixed(2)
                }
                onItemClick={(a) => onSelectCurr(a.id)}
              >
                {balancesAmount.map((val) => (
                  <DropdownMenuItem key={val.symbol} id={val.symbol}>
                    <RRow horizontalAlign="space-between" width={"100%"}>
                      <RRow>
                        <img src={val.img} alt="" width={"22px"} />
                        <SpaceHorizontal />
                        <RText font="Righteous">
                          {val.symbol.toUpperCase()}
                        </RText>
                      </RRow>

                      <RText font="Righteous">
                        ${val.usd_amount.toFixed(2)}
                      </RText>
                    </RRow>
                  </DropdownMenuItem>
                ))}
              </DropdownMenu>
              {/*  <div
              style={{
                backgroundColor: ColorPalette.accentColor2,
                padding: "6px 12px",
                borderRadius: "6px",
              }}
            >
              <RText>${userBalances.total_usd.toFixed(2)}</RText>
            </div> */}
              {/*   <div
              onClick={() => setState("wallet")}
              style={{
                cursor: "pointer",
                backgroundColor: "#EEA449",
                padding: "4px",
                borderRadius: "8px",
                marginLeft: "-8px",
                zIndex: 10,
              }}
            >
              <RiWalletLine color="white" size={22} />
            </div> */}
              <SpaceHorizontal />
              <img
                src={auth.currentUser.photoURL}
                width={"42px"}
                style={{ borderRadius: "100%" }}
                alt=""
              />
              <SpaceHorizontal />
            </RRow>
          </RRow>
        </div>
        <Space height="12px" />

        {pageSelected === 0 && (
          <>
            {currentGame?.loading && <LoadingScreen />}
            <img
              alt=""
              src={casinoBannImg}
              style={{ borderRadius: "12px" }}
              width={"100%"}
            />
            <Space height="6px" />

            {!filter.active && (
              <div
              /*   style={{
                  position: "sticky",
                  top: "80px",
                  zIndex: 5,
                }} */
              >
                <InputField
                  font="Righteous"
                  placeHolder={"Search"}
                  value={searchTerm}
                  setValue={handleSearchChange}
                  padding="12px 12px"
                />
              </div>
            )}

            <Space height="6px" />

            {!(searchTerm || filter.active) && (
              <>
                <SectionTitile icon={chipIcon}>Last bets</SectionTitile>
                <Space height="6px" />
                <HorizontalScrollContainer animScroll>
                  {lastBets.map((g) => (
                    <HorizontalScrollElement key={g.id}>
                      <RRow gap={"4px"} height={"60px"}>
                        <img
                          src={g.gameImg}
                          height={"100%"}
                          alt=""
                          style={{ borderRadius: "4px" }}
                        />
                        <RColumn
                          horizontalAlign="start"
                          verticalAlignment="center"
                          height={"100%"}
                        >
                          <RText color="grey" font="Righteous">
                            {g.gameName.slice(0, 10)}
                          </RText>
                          <RText font="Righteous" size="14px">
                            {`${g.username.slice(0, 2)}***${g.username.slice(
                              -2
                            )}`}
                          </RText>
                          <RText font="Righteous" color={greenColor}>
                            ${g.amount}
                          </RText>
                        </RColumn>
                      </RRow>
                    </HorizontalScrollElement>
                  ))}
                  {lastBets.length === 0 && (
                    <LoadingIcons.ThreeDots width={"26px"} />
                  )}
                </HorizontalScrollContainer>
                <Space />
                <SectionTitile icon={cherryIcon}>Providers</SectionTitile>
                <HorizontalScrollContainer>
                  {providers.map((g) => (
                    <HorizontalScrollElement>
                      <SingleProvider game={g} key={g.id} width="90px" />
                    </HorizontalScrollElement>
                  ))}
                </HorizontalScrollContainer>
                <Space />
                <SectionTitile icon={diceIcon}>Top Games</SectionTitile>
                <Space height="6px" />
                <HorizontalScrollContainer>
                  {topGames.map((g) => (
                    <HorizontalScrollElement key={g.id}>
                      <SingleGame game={g} key={g.id} width="90px" />
                    </HorizontalScrollElement>
                  ))}
                  {topGames.length === 0 && (
                    <LoadingIcons.ThreeDots width={"26px"} />
                  )}
                </HorizontalScrollContainer>
                <Space />
                <SectionTitile icon={historyIcon}>Last played</SectionTitile>
                <HorizontalScrollContainer>
                  {lastPlayed.map((g) => (
                    <HorizontalScrollElement>
                      <SingleGame game={g} key={g.id} width="90px" />
                    </HorizontalScrollElement>
                  ))}
                </HorizontalScrollContainer>

                <Space />
                {games.length > 0 &&
                  games.map((cat) => (
                    <div key={cat.cat.name}>
                      <RRow horizontalAlign="space-between">
                        <SectionTitile icon={cat.cat?.icon}>
                          {cat.cat.name}
                        </SectionTitile>
                        <div
                          style={{
                            backgroundColor: "#2d335c",
                            padding: "8px 12px",
                            borderRadius: "8px",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            setFilter({
                              active: true,
                              type: "category",
                              value: cat.cat.query,
                              label: cat.cat.name,
                            })
                          }
                        >
                          <RText color="#adadad" size="14px">
                            View all
                          </RText>
                        </div>
                      </RRow>

                      <Space height="6px" />
                      <HorizontalScrollContainer>
                        {cat.games.map((g) => (
                          <HorizontalScrollElement>
                            <SingleGame game={g} key={g.id} width="90px" />
                          </HorizontalScrollElement>
                        ))}
                      </HorizontalScrollContainer>
                      <Space height="32px" />
                    </div>
                  ))}
              </>
            )}
            {(searchTerm || filter.active) && (
              <>
                <div
                  onClick={() => {
                    setFilter({ active: false });
                    setSearchTerm("");
                  }}
                  style={{ padding: "6px", cursor: "pointer" }}
                >
                  <RRow>
                    <RiArrowLeftFill color="white" />
                    <RText font="Righteous">{filter.label}</RText>
                  </RRow>
                </div>
                <Space height="8px" />
                <RGrid
                  horizontalAlign={
                    filteredGames.length > 3 ? "space-between" : "start"
                  }
                >
                  {filteredGames.map((g) => (
                    <SingleGame game={g} key={g.id} width="30%" />
                  ))}
                  {filteredGames.length === 0 && (
                    <RText align={"center"}>No games found</RText>
                  )}
                </RGrid>
              </>
            )}
            <Space height="90px" />
            <RColumn horizontalAlign="center">
              <img src={gambleAwaereImg} width={"50%"} alt="" />
              <Space height="12px" />
              <a
                href="https://t.me/TheGameHubSupportBot"
                target="_blank"
                rel="noreferrer"
              >
                <RRow gap={"4px"}>
                  <RiTelegramLine color="white" size={22} />
                  <RText>Support</RText>
                </RRow>
              </a>
            </RColumn>
            <Space height="100px" />
          </>
        )}
        {pageSelected === 2 && <WalletPage />}
        {pageSelected === 1 && (
          <>
            <Space />
            <RRow gap={"12px"}>
              <img
                src={auth.currentUser.photoURL}
                width={"62px"}
                style={{ borderRadius: "12px" }}
                alt=""
              />
              <RTitile font="Righteous">{auth.currentUser.displayName}</RTitile>
            </RRow>
            <Space />

            <ContainerBorder>
              <RRow gap={"10px"}>
                <RiMoneyDollarCircleLine color="grey" size={28} />
                <RTitile font="Righteous">
                  $
                  {ngr?.totalBet ?? (
                    <LoadingIcons.ThreeDots height={"6px"} width={"22px"} />
                  )}
                </RTitile>
                <RText font="Righteous" color="grey">
                  wagered
                </RText>
              </RRow>
            </ContainerBorder>
            <Space height="4px" />
            <ContainerBorder>
              <RRow gap={"10px"}>
                <RiDice2Line color="grey" size={28} />
                <RTitile font="Righteous">
                  {ngr?.totalRounds ?? (
                    <LoadingIcons.ThreeDots height={"6px"} width={"22px"} />
                  )}
                </RTitile>
                <RText font="Righteous" color="grey">
                  rounds
                </RText>
              </RRow>
            </ContainerBorder>
            <Space height="4px" />

            <ContainerBorder>
              <RRow gap={"10px"}>
                <RiGamepadLine color="grey" size={28} />
                <RTitile font="Righteous">
                  {ngr?.totalGames ?? (
                    <LoadingIcons.ThreeDots height={"6px"} width={"22px"} />
                  )}
                </RTitile>
                <RText font="Righteous" color="grey">
                  games
                </RText>
              </RRow>
            </ContainerBorder>
          </>
        )}
        {pageSelected === 3 && (
          <>
            <Space />
            <RTitile font="Righteous">Coming soon</RTitile>
            <Space />
            <RText font="Righteous" size="16px" color="grey">
              Your affiliate link
            </RText>
            <Space height="8px" />
            <div onClick={copyCode} style={{ cursor: "pointer" }}>
              <RRow width={"100%"}>
                <RiFileCopyLine color="white" size={22} />
                <SpaceHorizontal />
                <RText font="Righteous" weight="bold" size="15px">
                  {linkRef}
                </RText>
              </RRow>
            </div>
            <Space height="12px" />
            <RRow horizontalAlign="space-between">
              <SecondaryBtn
                _width={"50%"}
                text={"Invite friends"}
                onClick={inviteFriends}
              />
              <SpaceHorizontal width="12px" />
              <SecondaryBtn
                _width={"50%"}
                text={"Post in story"}
                onClick={shareToStory}
              />
            </RRow>
          </>
        )}
      </MaxWidthContainer>
      <div
        style={{
          zIndex: 10,
          position: "fixed",
          width: "100%",
          height: "65px",
          background: `linear-gradient(to bottom, rgba(14, 22, 27, .85), ${ColorPalette.tabColor})`,
          bottom: 0,
          paddingTop: "12px",
        }}
      >
        <RRow
          width={"98%"}
          horizontalAlign="space-evenly"
          verticalAlignment="start"
          height={"100%"}
        >
          {pages.map((page, ind) => (
            <SingleTabBtn key={ind} page={page} />
          ))}
        </RRow>
      </div>
    </div>
  );
}
