import { toast } from "react-toastify";
import {
  EmojiPanel,
  RColumn,
  RRow,
  RText,
  RTitile,
  SecondaryBtn,
  Space,
  SpaceHorizontal,
} from "../compnents/otherCOmps";
import copyIcon from "../assets/copy.png";
import { useState } from "react";
import { collection, doc, getDoc } from "firebase/firestore";
import { auth, db } from "../firebase";
import { ContainerBorder } from "../compnents/compes";
import { isTest } from "../global/const";
import { RiSendPlane2Line } from "@remixicon/react";
import { showErrorNotification } from "../compnents/errorMessagesComp";
import { formatNumber, formatNumberInStr } from "../global/util";

export default function ReferralPage({ refCode }) {
  const linkRef =
    (isTest
      ? "https://t.me/test_hub_games_bot/games?startapp=ref="
      : "https://t.me/TheGameHubBot/play?startapp=ref=") + refCode;

  const [numInvited, setNumInvited] = useState();
  const [referredFrom, setReferredFrom] = useState({
    uid: null,
    username: null,
    type: null,
  });

  const [casinoProftShare, setCasinoProfitShare] = useState(30);
  const [commission, setCommisssion] = useState({ wagared: 0, commission: 0 });

  const queryParams = new URLSearchParams(window.location.search);
  const fromParam = queryParams.get("from");
  const isFromTg = fromParam === "miniapp" || fromParam === "gameTg";
  useState(() => {
    getData();
  }, []);

  async function getData() {
    const referralRef = doc(db, "referrals", auth.currentUser.uid);
    const refDoc = await getDoc(referralRef);
    if (!refDoc.exists()) return;
    setNumInvited(refDoc.data()["numReferrals"] ?? 0);
    setReferredFrom(refDoc.data().referredBy);
    setCasinoProfitShare(refDoc.data().casinoShare ?? 30);
    setCommisssion(refDoc.data().earn);
  }

  function inviteFriends() {
    const link = `https://t.me/share/url?url=${linkRef}&text=🕹️Join%20The%20Game%20Hub%20using%20my%20referral%20link`;
    if (fromParam === "miniapp") {
      window.Telegram.WebApp.openTelegramLink(link);
    } else {
      window.open(link, "_blank");
    }
  }
  function shareToStory() {
    const description =
      "Join TheGameHub with my ref code to earn points!\n" + linkRef;
    if (fromParam === "miniapp") {
      window.Telegram.WebApp.shareToStory(
        "https://testgamehub.crabsgames.com/botImg/storyvideo-2.mp4",
        {
          text: description,
        }
      );
    } else {
      window.alert("Not for desktop");
    }
  }
  function copyCode() {
    navigator.clipboard.writeText(linkRef);
    toast.success("Referral code copied", {
      position: "top-left",
      autoClose: 2000,
      hideProgressBar: false,
    });
  }

  const pointMilestone = [
    { num: 3, points: 25 },
    { num: 5, points: 50 },
    { num: 10, points: 100 },
    { num: 15, points: 150 },
    { num: 20, points: 200 },
  ];

  function SiglePointsBonus({ data }) {
    return (
      <RRow horizontalAlign="space-between">
        <RText color={numInvited >= data.num ? "green" : undefined}>
          {data.num} friends
        </RText>
        <RText color={numInvited >= data.num ? "green" : undefined}>
          +{data.points} points
        </RText>
      </RRow>
    );
  }
  return (
    <>
      <Space />
      <RTitile>Referral system</RTitile>
      <Space />
      <RText size="16px" color="grey">
        How it works
      </RText>
      <Space height="8px" />
      <RText size="14px">
        Invite your friends to join the fun on our all-in-one platform by
        sharing your personal referral link. They'll love the excitement, and
        you'll both earn amazing rewards.
      </RText>
      <Space />
      <ContainerBorder gradientBg>
        <RText>Earn 50 points for each player you refer!</RText>
      </ContainerBorder>
      <Space height="12px" />
      <RText size="16px" color="grey">
        Next bonus points milestone
      </RText>
      <ContainerBorder>
        {pointMilestone.map((p) => (
          <SiglePointsBonus key={p.num} data={p} />
        ))}
      </ContainerBorder>
      <Space height="6px" />
      <ContainerBorder>
        <RRow horizontalAlign="space-between">
          <RText size="18px" color={"grey"}>
            Casino proft share
          </RText>
          <RText size="22px">{casinoProftShare ?? "30"}%</RText>
        </RRow>
      </ContainerBorder>
      <Space />
      <RText size="16px" color="grey">
        Your affiliate link
      </RText>
      <Space height="8px" />
      <div onClick={copyCode} style={{ cursor: "pointer" }}>
        <RRow width={"100%"}>
          <img src={copyIcon} width={"18px"} />
          <SpaceHorizontal />
          <RText weight="bold" size="15px">
            {linkRef}
          </RText>
        </RRow>
      </div>
      <Space height="12px" />
      <RRow horizontalAlign="space-between">
        <SecondaryBtn
          _width={"50%"}
          text={"Invite friends"}
          onClick={inviteFriends}
        />
        <SpaceHorizontal width="12px" />
        <SecondaryBtn
          _width={"50%"}
          text={"Post in story"}
          onClick={shareToStory}
        />
      </RRow>
      <Space height="40px" />
      {referredFrom && referredFrom.uid && (
        <>
          <RText size="16px" color={"grey"}>
            Referrerd by
          </RText>
          <RText>
            {referredFrom.type !== "partner" ? "@" : ""}
            {referredFrom.username}
          </RText>
          <Space />
        </>
      )}
      <ContainerBorder width="100%">
        <RRow horizontalAlign="space-between">
          <RText size="18px" color={"grey"}>
            Invited users
          </RText>
          <RText size="22px">{numInvited ?? "-"}</RText>
        </RRow>
      </ContainerBorder>
      <ContainerBorder isMobile={false} width="100%">
        <RRow horizontalAlign="space-between">
          <RText size="18px" color={"grey"}>
            Referrals wagered
          </RText>
          <RText size="22px">
            ${formatNumberInStr(commission?.wagared ?? 0)}
          </RText>
        </RRow>
      </ContainerBorder>
      <ContainerBorder width="100%">
        <RRow horizontalAlign="space-between">
          <RText size="18px" color={"grey"}>
            Your commission
          </RText>
          <RText size="22px">
            ${formatNumberInStr(commission?.commission ?? 0)}
          </RText>
        </RRow>
      </ContainerBorder>
      <RText size="14px" color="grey">
        Updated every week
      </RText>
      <Space height="90px" />
    </>
  );
}
