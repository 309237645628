import axios from "axios";
import { supabase } from "../supabase";
import { auth, db } from "../firebase";
import { collection, getDocs, limit, query, orderBy } from "firebase/firestore";
import { isTest } from "../global/const";

const baseUrl = "https://uno.thegamehub.gg:3009";
const baseHead = {
  sign: "7qf1oyipq5m7od27n75kli2y8c7iek3x",
};

export class CasinoApi {
  static getPopularGames = async () => {
    const customTopGames = [
      "HAK-wanteddeadorawild",
      "PPC-sugarrush",
      "PPC-gatesofolympus",
      "PPC-zeusvshadesgodsofwar",
      "HAK-2wild2die",
      "HAK-chaoscrew",
      "HAK-lebandit",
      "PPC-fruitparty",
      "HAK-ripcity",
      "NE-gonzosquest",
      "PPC-sweetbonanza",
    ];

    const res = await axios.get(`${baseUrl}/gamepopular`, {
      headers: baseHead,
    });

    const idsQtech = res.data.items.map((g) => g.id);

    const ids = [...idsQtech, ...customTopGames].sort(
      () => Math.random() - 0.5
    );
    const games = await supabase
      .from("qt_games")
      .select("id, name, images")
      .in("id", ids);
    console.log(games.data);
    return games.data;
  };

  static getLastBets = async () => {
    const res = await axios.get(`${baseUrl}/lasttransaction`, {
      headers: baseHead,
    });
    console.log(res.data);
    let items = res.data.items;

    items = items.sort((a, b) => {
      const dateA = new Date(a.created.replace("[UTC]", ""));
      const dateB = new Date(b.created.replace("[UTC]", ""));
      return dateB - dateA;
    });

    //items = items.slice(0, 40);

    // Estrai i gameId unici
    const gameIds = [...new Set(items.map((item) => item.gameId))];

    // Recupera i dettagli dei giochi da Supabase
    const { data: games, error } = await supabase
      .from("qt_games")
      .select("id, name, images")
      .in("id", gameIds);

    if (error) {
      console.error("Errore nella query a Supabase:", error);
      return items; // Restituisce gli items senza aggiungere gameName e gameImg
    }

    // Crea una mappa per accedere rapidamente ai dettagli del gioco
    const gameMap = new Map(games.map((game) => [game.id, game]));

    // Aggiungi `gameName` e `gameImg` agli items
    items = items.map((item) => {
      const game = gameMap.get(item.gameId);
      return {
        ...item,
        gameName: game ? game.name : null,
        gameImg: game ? game.images[2].url : null,
      };
    });

    console.log(items);
    return items;
  };

  static getCasinoLink = async () => {
    const usTok = await auth.currentUser.getIdToken();
    const res = await axios.get(`${baseUrl}/getCasinoLink`, {
      headers: { ...baseHead, "user-token": usTok },
    });
    let link = res.data.link;
    if (isTest) {
      link = "https://test-branch2.pages.dev/#/" + link.split("/#/")[1];
    }
    console.log(link);
    return link;
  };

  static getLastPlayed = async () => {
    const usTok = await auth.currentUser.getIdToken();
    const res = await axios.get(`${baseUrl}/getlastplayed`, {
      headers: { ...baseHead, "user-token": usTok },
    });

    console.log(res.data);
    return res.data;

    const c = collection(db, "users", auth.currentUser.uid, "qt-transactions");
    const q = query(c, orderBy("date", "desc"), limit(40));
    const lastPlayed = await getDocs(q);

    const gameIds = lastPlayed.docs
      .map((d) => d.data().gameId)
      .filter((gameId, index, self) => self.indexOf(gameId) === index);

    const games = await supabase
      .from("qt_games")
      .select("id, name, images")
      .in("id", gameIds);

    const orderedGames = gameIds.map((gameId) =>
      games.data.find((game) => game.id === gameId)
    );

    return orderedGames;
  };

  static getProviders = async () => {
    const { data } = await supabase.rpc("get_distinct_providers");

    const p = data.map((pr) => ({
      id: pr.provider_id,
      name: pr.provider_name,
      img: `https://lobby.qtlauncher.com/images/?id=${pr.provider_id}_en_US&type=providers`,
    }));
    return p;
  };

  static getNgr = async () => {
    const usTok = await auth.currentUser.getIdToken();
    const res = await axios.get(`${baseUrl}/ngr`, {
      headers: { ...baseHead, "user-token": usTok },
    });
    return res.data;
  };
}
