import { useEffect, useState } from "react";
import "./App.css";
import { HomePage } from "./home/homePage";
import { auth, db, functions, logOut } from "./firebase";
import {
  BrowserRouter,
  Routes,
  Route,
  MemoryRouter,
  useNavigate,
  useLocation,
} from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import ProfilePage from "./profile/profilePage";
import LobbyPage from "./lobby/lobbyPage";
import CheckersGames from "./checkers/checkersGame";
import {
  Padding,
  PrimaryBtn,
  RColumn,
  RRow,
  RText,
  RTitile,
  SecondaryBtn,
  Space,
  SpaceHorizontal,
} from "./compnents/otherCOmps";
import logoImg from "./assets/logo.png";
import ScoreGame from "./pvpgames/scoresGames";
import RouletteGame from "./roulette/roulette";
import P2eGame from "./p2eGames/p2eGames";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import TabController from "./home/tabController";
import RacingGame from "./pvpgames/racingGame";
import {
  showErrorNotification,
  showSuccessNotification,
} from "./compnents/errorMessagesComp";
import F2PGames from "./f2pGames/f2pGames";
import {
  BaseInfo,
  ColorPalette,
  GroupsDoc,
  StatusInfo,
  TaskDoc,
  UserInfo,
  casinoVersion,
  isTest,
  versionGame,
} from "./global/const";
import NoActivePage from "./home/noActivePage";
import PvpMovesGames from "./pvpgames/pvpMovesGame";
import ScoreGameRealTime from "./pvpgames/scoreRealtimeGames";
import EvenBetPage from "./evenbet/evenbetPage";
import QTCasinoPage from "./qt-casino/qtCasinoPage";
import { httpsCallable } from "firebase/functions";
import { useGameType } from "./theme";
import StartBanners from "./startbanners/startBanner";
import LoadingIcons from "react-loading-icons";
import { signInWithCustomToken } from "firebase/auth";
import axios from "axios";
import { copyString, getLevel, getLevelInd } from "./global/util";
import { ToastContainer } from "react-toastify";
import {
  RiCloseCircleFill,
  RiCloseFill,
  RiFileCopy2Line,
  RiFileCopyLine,
} from "@remixicon/react";
import DiffertGame from "./pvpgames/differtGames";
import QTLobbyPage from "./qt-casino/qtlobby";
import CasinoApp from "./qt-casino/qt-app";

export var tgUsernameGlob = "";
export var telegramID;

export let eth_address;

export default function Starting() {
  const [tgUsername, setTgUsername] = useState(null);
  const location = useLocation();
  const nav = useNavigate();
  const [noActiveMsg, setNoActiveMsg] = useState("");
  const {
    gameType,
    setGameType,
    isTestInProd,
    setQtSelectedCurrency,
    updateUserBalances,
    setUserPoints,
  } = useGameType();

  const [state, setState] = useState("loading");

  useEffect(() => {
    if (location.pathname !== "/") {
      nav("/");
    }
    const unsubscribe = auth.onAuthStateChanged(async (authUser) => {
      setState("loading");
      const statusD = await getDoc(doc(db, "aBase/status"));
      StatusInfo.data = statusD.data();
      if (
        isTest === false &&
        !isTestInProd &&
        StatusInfo.data.active === false
      ) {
        setState("no_active");
        setNoActiveMsg(StatusInfo.data.no_active_msg);
        return;
      }

      if (authUser) {
        tgUsernameGlob = authUser.displayName ?? "";

        if (!authUser.displayName || authUser.displayName === "undefined") {
          {
            setState("no_username");
            return;
          }
        }

        setTgUsername(tgUsernameGlob);
        //console.log(tgUsernameGlob);
        if (isTest || isTestInProd) {
          //check if tester
          const testDoc = await getDoc(doc(db, "tester/" + authUser.uid));
          if (!testDoc.exists()) {
            setState("no_tester");
            return;
          }
        }
        await loadData(authUser.uid);
        setState("logged");
        const queryParams = new URLSearchParams(window.location.search);
        const pageParam = queryParams.get("page");
        if (
          [
            "friends",
            "task",
            "wallet",
            "referral",
            "challenge",
            "games",
          ].includes(pageParam)
        ) {
          setGameType("games");
        }

        if (pageParam === "casino")
          setGameType(casinoVersion === "ric" ? "casino2" : "casino"); //TODO
      } else {
        setState("loading");
        //get if from mini app
        const queryParams = new URLSearchParams(window.location.search);

        let token;

        if (queryParams.get("from") === "miniapp") {
          const initData = window.Telegram.WebApp.initData;
          const startParam = window.Telegram.WebApp.initDataUnsafe.start_param;

          try {
            let refCode;
            const fromTgType = startParam ? "direct-link" : "bot";

            if (startParam && startParam.includes("ref=")) {
              refCode = startParam.split("ref=")[1];
            } else {
              refCode = queryParams.get("refCode");
            }
            const response = await axios.post(
              "https://us-central1-html5-gaming-bot.cloudfunctions.net/getTokenAuthFromMiniApp",
              {
                initData,
                from: isTest ? "test" : "prod",
                refCode,
                fromTgType: fromTgType,
              }
            );
            if (response.data.is_in_community === false) {
              setState("no_community");
              return;
            }
            if (response.data.valid) {
              console.log("Dati validi:", response.data);
              token = response.data.token;
            } else {
              setState("error_token");
              if (response.data.tgMsg) {
                showErrorNotification(response.data.tgMsg);
              } else {
                showErrorNotification("Data not successfully verified");
              }

              return;
            }
          } catch (error) {
            console.log(error);
            setState("error_token");
            showErrorNotification("Data not successfully verified");
            return;
          }
        } else {
          token = urlObject.searchParams.get("token");
          if (!token) {
            setState("no_token");
            return;
          }
        }

        try {
          await signInWithCustomToken(auth, token);
        } catch (e) {
          setState("error_token");
          showErrorNotification("Invalid token");
          return;
        }
      }
    });

    const url = window.location.href;
    const urlObject = new URL(url);
    const startParam = window.Telegram.WebApp.initDataUnsafe.start_param;

    let rid;
    if (startParam && startParam.includes("room=")) {
      rid = startParam.split("room=")[1];
    } else {
      rid = urlObject.searchParams.get("roomID");
    }

    if (
      !urlObject.searchParams.get("ignoreparams") &&
      startParam &&
      startParam.includes("gurl=")
    ) {
      let currentUrl = new URL(window.location.href);

      // Aggiungi o modifica i parametri
      const gurl = startParam.split("gurl=")[1];
      currentUrl.searchParams.set("gameUrl", gurl);
      currentUrl.searchParams.set("page", "games");
      window.history.pushState({}, "", currentUrl.toString());
    }
    if (rid && rid.length === 6) {
      setGameType("games");
      goToRoomInvited(rid);
    }
    if (startParam && startParam.includes("wallet")) {
      setGameType("games");
    }

    return () => {
      unsubscribe();
    };
  }, [isTestInProd]);
  const [userRefCode, setUserRefCode] = useState("");
  async function loadData(uid) {
    //post login
    const funct = httpsCallable(functions, "postOpenGame");
    funct()
      .then((d) => console.log(d.data))
      .catch((e) => console.log(e));

    //get base info
    const statusD = await getDoc(doc(db, "aBase/status"));
    StatusInfo.data = statusD.data();
    if (isTest === false && !isTestInProd && StatusInfo.data.active === false) {
      setState("no_active");
      setNoActiveMsg(StatusInfo.data.no_active_msg);
      return;
    }

    const userDoc = doc(db, "users", auth.currentUser.uid);
    const userD = await getDoc(userDoc);
    updateUserBalances(userD.data()?.balances ?? {});
    setUserPoints(userD.data()?.points ?? 0);
    eth_address = userD.data()?.eth_address;
    setUserRefCode(userD.data()?.code);

    //console.log(await auth.currentUser.getIdToken());
    const taskD = await getDoc(doc(db, "aBase/task"));
    TaskDoc.tasks = taskD.data().tasks.sort((a, b) => a.index - b.index);
    TaskDoc.points = taskD.data().points;
    const baseInfoD = await getDoc(doc(db, "aBase/baseInfo"));
    BaseInfo.baseInfoDoc = baseInfoD.data();
    BaseInfo.gamesNow = baseInfoD.data().gamesNow;
    BaseInfo.gamesName = baseInfoD.data().gamesName;
    BaseInfo.chainTokensData = baseInfoD.data().chainTokensData;
    BaseInfo.gamesMaxPlayers = baseInfoD.data().gamesMaxPlayers;
    BaseInfo.gamesPvpUrlType = baseInfoD.data().gamesPvpUrlType;
    BaseInfo.levelsThreshold = baseInfoD.data().levelsThreshold;
    BaseInfo.p2eGames = baseInfoD.data().p2eGames;
    BaseInfo.levels2 = baseInfoD.data().levels2;
    //  BaseInfo.p2eGames.games_active = [{ url: "flappyChicken", attempts: 3 }];
    BaseInfo.games = baseInfoD.data().games;
    const groupsDoc = await getDoc(doc(db, `users/${uid}/other/groups`));
    GroupsDoc.groups = groupsDoc.data()?.groups ?? [];
    UserInfo.myLevel = getLevel(userD.data()?.balances?.ghub?.amount ?? 0);
    setQtSelectedCurrency(userD.data()?.qtSelectedCurrency ?? "eth");

    //check if is waiting room
    const tenMinutesAgoTimestamp = Date.now() - 15 * 60 * 1000;
    const gamesRef = collection(db, "games");
    const q = query(
      gamesRef,
      where("players", "array-contains", {
        uid: auth.currentUser.uid,
        username: auth.currentUser.displayName,
      }),
      where("creationDate", ">=", tenMinutesAgoTimestamp),
      limit(1),
      where("isDiffert", "==", false)
    );
    const querySnapshot = await getDocs(q);

    const q2 = query(
      gamesRef,
      where("players", "array-contains", {
        uid: auth.currentUser.uid,
        username: auth.currentUser.displayName,
      }),
      where("players_count", "==", 1),
      where("isDiffert", "==", true),
      where("state", "==", "waiting"),
      limit(1)
    );
    const querySnapshot2 = await getDocs(q2);

    if (!querySnapshot.empty) {
      const gameDoc = querySnapshot.docs[0];
      //console.log(gameDoc.data());
      nav("/lobby", {
        state: {
          gameUrl: gameDoc.data().gameUrl,
          alreadyInGameWaitingData: {
            roomID: gameDoc.id,
            code: gameDoc.data().code,
            level: gameDoc.data().level ?? false,
            isOpen: gameDoc.data().isOpen,
            player1: gameDoc.data().player1,
          },
        },
      });
      setGameType("games");
      return;
    }
    if (!querySnapshot2.empty) {
      const gameDoc = querySnapshot2.docs[0];
      if (gameDoc.data().differtPlayerFinished === true) return;
      nav("/scoreGame", {
        state: {
          url: gameDoc.data().gameUrl,
          gameID: gameDoc.id,
          level: gameDoc.data().level,
          price: gameDoc.data().price,
          firstToPlay: gameDoc.data().players_count === 1,
          gameCode: gameDoc.data().code,
        },
      });
      setGameType("games");
    }
  }

  async function goToRoomInvited(code) {
    nav("/lobby", {
      state: {
        invitedToGame: {
          code: code,
        },
      },
    });
  }

  if (state === "no_community") {
    return (
      <>
        <RColumn
          width={"100%"}
          height={"100vh"}
          verticalAlignment="center"
          horizontalAlign="center"
        >
          <img src={logoImg} width={"90px"} />
          <Space />
          <RTitile align={"center"} color="orange">
            Enter the telegram community group before joining!
          </RTitile>
          <Space />
          <Space />
          <PrimaryBtn
            text={"Join"}
            onClick={() => {
              window.open("https://t.me/TheGameHubIO");
            }}
          />
          <Space />
          <SecondaryBtn
            text={"Continue"}
            onClick={() => window.location.reload()}
          />

          <Space height="90px" />
          <RText size="12px">v {versionGame}</RText>
        </RColumn>
      </>
    );
  }
  if (state === "no_token" || state === "error_token") {
    return (
      <RColumn width={"100%"} height={"100vh"} verticalAlignment="center">
        <img src={logoImg} width={"90px"} />
        <Space />
        <RTitile align={"center"} color="red">
          {state === "no_token"
            ? "No token auth"
            : "Invalid token auth, exit and reopen the telegram link"}
        </RTitile>
        <Space />
        <RText size="12px">v {versionGame}</RText>
      </RColumn>
    );
  }
  if (state === "loading") {
    return (
      <RColumn width={"100%"} height={"100vh"} verticalAlignment="center">
        <img src={logoImg} width={"90px"} alt="Game hub" />
        <Space />
        <RTitile>GM {auth?.currentUser?.displayName ?? ""}</RTitile>
        <Space />
        <LoadingIcons.ThreeDots width={"26px"} />
        <RText size="12px">v {versionGame}</RText>
      </RColumn>
    );
  }
  if (state === "no_active") {
    return <NoActivePage msg={noActiveMsg} />;
  }

  if (state === "no_username") {
    return (
      <RColumn
        horizontalAlign="center"
        verticalAlignment="center"
        height={"90vh"}
        width={"100vw"}
      >
        <RColumn width={"92%"} bgColor={"red"}>
          <Space />
          <Space />
          <Space />
          <img src={logoImg} width={"100px"} />
          <Space />
          <RTitile>No Username</RTitile>
          <Space />
          <RText align={"center"}>
            You need a Telegram username to play, set it in the Telegram
            settings and come back!
          </RText>
          <SecondaryBtn text={"log out"} onClick={logOut} />
        </RColumn>
      </RColumn>
    );
  }

  if (state === "no_tester") {
    return (
      <>
        <ToastContainer />
        <RColumn
          horizontalAlign="center"
          verticalAlignment="center"
          height={"90vh"}
          width={"100vw"}
        >
          <RColumn width={"92%"} bgColor={"red"}>
            <Space />
            <Space />
            <Space />
            <img src={logoImg} width={"100px"} alt="" />
            <Space />
            <RTitile>You are not a tester</RTitile>
            <Space />
            <RText align={"center"}>
              Contact the admins to add you as tester, your id is
            </RText>
            <Space />
            <div onClick={() => copyString(auth.currentUser.uid, "id copied")}>
              <RRow>
                <RiFileCopyLine color="white" widths={13} />
                <SpaceHorizontal />
                <RText>{auth.currentUser.uid}</RText>
              </RRow>
            </div>
            <Space />
            <SecondaryBtn onClick={logOut} text={"Logout"} />
          </RColumn>
        </RColumn>
      </>
    );
  }

  if (state === "logged") {
    if (gameType === "init") {
      return <StartBanners />;
    } else if (gameType === "games") {
      return (
        <>
          {/* <button onClick={logOut}>o</button> */}

          <Routes>
            <Route
              path="/"
              element={<TabController tgUsername={tgUsername} />}
            />
            <Route exact path="/lobby" element={<LobbyPage />} />
            <Route exact path="/checkers" element={<CheckersGames />} />
            <Route exact path="/race" element={<RacingGame />} />
            <Route exact path="/p2e" element={<P2eGame />} />
            <Route exact path="/free" element={<F2PGames />} />
            <Route exact path="/scoreGame" element={<DiffertGame />} />
            <Route exact path="/pvpMoveGame" element={<PvpMovesGames />} />
            <Route
              exact
              path="/scoreRealTime"
              element={<ScoreGameRealTime />}
            />
            <Route exact path="/roulette" element={<RouletteGame />} />
            <Route exact path="/poker" element={<EvenBetPage />} />
            <Route exact path="/casino" element={<QTCasinoPage />} />
            <Route
              exact
              path="/profile"
              element={<ProfilePage tgUsername={tgUsername} />}
            />
          </Routes>
        </>
      );
    } else if (gameType === "casino") {
      return <CasinoApp />;
    } else if (gameType === "casino2") {
      return <QTLobbyPage refCode={userRefCode} />;
    } else if (gameType === "poker") {
      return <EvenBetPage />;
    }
  }
}
