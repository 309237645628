import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Unity, useUnityContext } from "react-unity-webgl";
import { auth, db, functions } from "../firebase";
import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import {
  LoadingScreen,
  PrimaryBtn,
  RColumn,
  RText,
  SecondaryBtn,
  Space,
} from "../compnents/otherCOmps";
import { telegramID, tgUsernameGlob } from "../aStartingPage";
import { httpsCallable } from "firebase/functions";
import { showErrorNotification } from "../compnents/errorMessagesComp";
import { ToastContainer } from "react-toastify";

export default function RacingGame() {
  const location = useLocation();
  const { gameID, isCasa, players_count } = location.state;
  const nav = useNavigate();
  let [h, setH] = useState(window.innerHeight);
  const [isRaceFinished, setIsRaceFinished] = useState(false);
  const [isRaceStarted, setIsRaceStarted] = useState(false);
  const [isWaitingWin, setIsWaitingWin] = useState(false);

  const gameWebglUrl = "racing18/Build/KartGameBuild";
  const { unityProvider, isLoaded, sendMessage, unload } = new useUnityContext({
    loaderUrl: gameWebglUrl + ".loader.js",
    dataUrl: gameWebglUrl + ".data.unityweb",
    frameworkUrl: gameWebglUrl + ".framework.js.unityweb",
    codeUrl: gameWebglUrl + ".wasm.unityweb",
  });

  const handleResize = () => {
    setH(window.innerHeight);
  };
  useEffect(() => {
    if (isLoaded) {
      window.GetRoomID = (callbackName) => {
        //console.log("callbackName", callbackName);
        sendMessage("callingfunction", callbackName, gameID);
      };

      window.ISHost = (callbackName) => {
        //console.log("the callback", callbackName);
        sendMessage("callingfunction", callbackName, isCasa ? "true" : "false");
      };

      window.PlayerName = (callbackName) => {
        sendMessage(
          "callingfunction",
          callbackName,
          auth.currentUser.displayName
        );
      };

      window.numOfPlayers = (callbackName) => {
        sendMessage("callingfunction", callbackName, players_count);
      };

      window.onGameStarted = () => {
        //console.log("onGameStarted");
        setIsRaceStarted(true);
        //  startGame();
      };

      window.EndDetail = (data, item) => {
        //console.log("EndDetail",data)
        //console.log("ItemDetail",item)
        if (item === 1) {
          onWin();
        }
        setIsRaceFinished(true);
      };
    }
  }, [isLoaded]);

  /*  async function startGame(){
        //console.log("start game");
        //console.log(gameID);
        const funct = httpsCallable(functions, "startGameRoom");
        const dat = {
            gameID: gameID,
        }
        try{
           const r = await funct(dat);
           //console.log(r.data);
           if(r.data.success === false){
                //showErrorNotification("Error");
                throw "error";
            }
            //console.log(r.data);
        }catch(e){
            showErrorNotification("Error starting room");
            //console.log(e);
        }
  } */
  async function onWin() {
    //console.log("WIN");
    const funct = httpsCallable(functions, "onRacingWin");
    const dat = {
      gameID: gameID,
    };
    try {
      setIsWaitingWin(true);
      const r = await funct(dat);
      //console.log(r.data);
      if (r.data.success === false) {
        //showErrorNotification("Error");
        throw "error";
      }
      //console.log(r.data);
      setIsWaitingWin(false);
    } catch (e) {
      showErrorNotification("Error win");
      //console.log(e);
    }
  }
  async function back() {
    await unload();
    nav("/?username=" + tgUsernameGlob + "&id=" + telegramID);
  }
  async function closeRoom() {
    const partitaDocRef = doc(db, "games", gameID);
    await deleteDoc(partitaDocRef);
    back();
  }
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
      />
      {!isLoaded && <LoadingScreen txt="Loading game..." />}
      {isRaceFinished && (
        <div
          style={{
            position: "fixed",
            zIndex: 10,
            width: "100%",
            height: "98vh",
          }}
        >
          <RColumn
            verticalAlignment="end"
            horizontalAlign="center"
            height={"100%"}
          >
            {!isWaitingWin && <PrimaryBtn onClick={back} text={"Close"} />}
            {isWaitingWin && <RText>Calculating win...</RText>}
            <Space height="50px" />
          </RColumn>
        </div>
      )}
      {/*
        {!isRaceStarted && isCasa &&
        <div style={{position: "fixed", zIndex: 10, bottom: "50px", left: "50px"}}>
            <SecondaryBtn onClick={closeRoom} text={"Close room"}/>
          
        </div>}
        */}
      <Unity
        unityProvider={unityProvider}
        style={{ width: "100%", height: "100vh" }}
      />
    </>
  );
}
